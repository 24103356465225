.new-listing-container {
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background-color: #f9fcff;
  padding: 2px 17px 50px 16px;

  .procedure-container {
    background-color: transparent !important;
    padding: 0px !important;
  }

  .ui.selection.dropdown {
    padding: 7px 12px;
    // height: 34px !important;
    // padding: 0.58571429em 2.1em 0.58571429em 1em !important;
    // margin: auto 0 !important;
    // display: flex;
    // align-items: center;
    // .divider.text {
    //   top: 3px;
    // }
    &.multiple {
      height: fit-content !important;
      .dropdown.icon {
        top: 1em !important;
      }
    }
  }

  .new-listing-title {
    background: none;
    font-family: "Roboto", sans-serif !important;
  }

  .pill {
    border-radius: 10px;
    border: 1px solid #e9f2ff;
    background: #e9f2ff;
    color: #0c66e4;
    // min-width: 67px;
    text-align: start;
    // font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 3px 5px;
    min-width: 87px;
    &.yellow {
      background-color: #fbbd08;
      color: #fff;
    }
    &.orange {
      background-color: orange;
      color: #fff;
    }
    &.green {
      background-color: green;
      color: #fff;
    }
    &.active-green {
      background-color: rgba(79, 171, 104, 0.1);
      color: #4fab68;
      border: 1px solid #4fab68;
    }

    &.pending-yellow {
      background-color: rgba(226, 178, 3, 0.1);
      color: #e2b203;
      border: 1px solid #e2b203;
    }
    &.inactive-grey {
      background-color: #f5f6f8;
      color: #788ca5;
      border: 1px solid #788ca5;
    }
    &.high {
      background-color: #d14040;
      color: #ffffff;
    }
    &.red {
      background-color: #d14040;
      color: #ffffff;
    }
    &.high-low {
      background-color: rgba(209, 64, 64, 0.1);
      color: rgba(209, 64, 64, 1);
      border: 1px solid rgba(209, 64, 64, 1);
    }
    &.medium {
      background-color: #e2b203;
      color: #ffffff;
    }
    &.low {
      background-color: #4fab68;
      color: #ffffff;
    }
    &.grey {
      color: #44546f !important;
      /* Body Medium 14\20 */
      // font-family: "Roboto";
      font-size: 14px !important;

      line-height: 20px !important; /* 142.857% */
      letter-spacing: 0.25px;
      background-color: #e4e6ea;
    }
    &.none {
      background-color: #788ca5;
      color: #e4e6ea !important;
    }
  }

  .table-header {
    margin-right: 23px;

    .header-date {
      p {
        text-align: right;
        margin-bottom: 15px;
        color: #44546f;
        // font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.25px;
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    // .add-item-btn {
    //   border: 2px solid #24b4fb;
    //   background-color: #24b4fb;
    //   border-radius: 0.9em;
    //   cursor: pointer;
    //   padding: 0.6em 1.2em 0.6em 1em;
    //   transition: all ease-in-out 0.2s;
    //   font-size: 16px;

    //   span {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     color: #fff;
    //     font-weight: 600;
    //   }

    // }

    .add-item-btn:hover {
      background-color: #0a55bd !important;
    }
  }

  h2.ui.header {
    font-family: "Roboto", sans-serif;
    background: transparent;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    color: #172b4d;
    font-style: normal;
    line-height: 32px;
    /* 133.333% */
  }

  .ui.primary.button {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: #0c66e4;
    border-radius: 3px;
    // width: 203px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-right: 0;
  }

  .no-new-listing-text {
    color: #788ca5;
    /* Title Large 22\28 */
    //font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 330px;
    line-height: 28px;
    /* 127.273% */
    margin-top: 8px;
  }

  .no-new-listing-container {
    text-align: center;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .new-listing-filter {
    border-radius: 10px;
    border: 1px solid rgba(14, 40, 87, 0.03);
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(14, 40, 87, 0.02);
    background-color: #fff;
    padding: 23px 24px 30px 31px;

    .filters-wrapper {
      margin-bottom: 0px;
    }
    .username-container {
      margin-left: 10px;

      .user-text {
        margin: 0px !important;
        color: #172b4d;
        // text-align: center;
        // font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
      }

      .user-role {
        margin: 0px !important;
        // margin-right: 8px !important;
        color: #788ca5;
        text-align: center;
        // font-family: 'Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
      }

      .people-text {
        margin: 0px !important;
        color: #172b4d;
        text-align: start;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0.15px;
      }

      .people-role {
        margin: 0px !important;
        // margin-right: 8px !important;
        color: #788ca5;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 142.857% */
        letter-spacing: 0.15px;
      }
    }

    .datatable-div {
      border-radius: 0px;
    }

    .no-data-container {
      text-align: center;
      height: 675px;
      display: flex;
      align-items: center;
      justify-content: center;

      .no-work-orders {
        .link-text {
          color: #0c66e4;
        }
        p {
          margin-top: 23px;
          margin-bottom: 32px;
          font-size: 28px !important;
          font-weight: 400 !important;
        }
        .work-order-button-transparent {
          border-radius: 3px;
          border: 1px solid #0c66e4;
          /* .Internal/Button/Button Label */
          background-color: #e9f2ff;
          padding: 6px 24px;

          color: #0c66e4;
          text-align: center;
          /* Body Large 16\24 */
          font-size: 16px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: 0.5px;
          &:hover {
            background-color: #0c66e4 !important;
            color: #e9f2ff !important;
          }
        }
      }
    }
    .ui.basic.buttons {
      .day-btns {
        gap: 0px !important;
      }
    }
    .no-data-text {
      color: #44546f;
      /* Title Large 22\28 */
      //font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 330px;
      line-height: 28px;
      /* 127.273% */
      margin-top: 8px;

      .work-order-tooltip {
        padding: 0 10px;
        margin: -2px 0px -1px 0px;
      }
    }

    .filters {
      .views {
        .list-view-option {
          display: flex;
          gap: 32px;
          margin-top: 8px;
          .single-view {
            text-align: center;

            // font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;

            justify-content: center;
            align-items: center;

            a {
              color: #172b4d;
            }

            a.active {
              background-color: #4fab68;
              border-radius: 5px;

              padding: 2px 16px;
              color: #fff;
              border: 1px solid;
            }
          }
        }
        .calendar-view-options {
          color: #44546f;
          text-align: center;
          /* Body Medium 14\20 */
          // font-family: 'Roboto';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.25px;
          display: flex;
          gap: 15px;
          align-items: center;
          > div {
            padding: 2px 8px;
            cursor: pointer;
            &.active {
              color: #0c66e4 !important;
              background-color: #e9f2ff;
            }
          }
        }
      }

      .timesheet-filter {
        gap: 24px !important;

        .calendar-view-options {
          color: #44546f;
          text-align: center;
          /* Body Medium 14\20 */
          // font-family: 'Roboto';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.25px;
          display: flex;
          gap: 15px;
          align-items: center;
          > div {
            padding: 2px 8px;
            cursor: pointer;
            &.active {
              color: #0c66e4 !important;
              background-color: #e9f2ff;
            }
          }

          &.timesheet {
            margin-bottom: 30px !important;
          }
        }
      }
      .rmdp-input {
        border: none !important;
        max-width: 100px;
        color: #44546f;
        text-align: center;
        /* Title Medium 16\24 */
        // font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.15px;
        padding-bottom: 2px;

        ::placeholder {
          // font-family: 'Roboto', sans-serif;
          color: #cecece !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.25px;
        }
      }
    }

    .filters-wrapper > div > .ui.input.icon {
      width: 240px;
    }

    .ui.input > input {
      border: 2px solid rgba(9, 30, 66, 0.14);
      border-radius: 5px !important;
      height: 34px;
      font-size: 14px;
      font-weight: 400;
      color: #172b4d;
      padding-left: 35px !important;

      &::placeholder {
        color: #44546f !important;
        font-size: 14px;
      }
      &:focus {
        border: 2px solid blue !important ;
      }
    }

    .ui.icon.input > i.icon {
      display: none;
    }

    .table-searchbar {
      position: relative;

      .upload-btn {
        // max-width: 127px;
        color: #0c66e4;
        text-align: center;
        padding: 1px 12px;

        // font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 3px;
        border: 2px solid #0c66e4;
        background: #e9f2ff;

        &:hover {
          background-color: #0c66e4;
          color: white;
        }
      }

      > svg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 10px;
        z-index: 1;
      }

      &.rtl-searchbar {
        svg {
          left: 16px !important;
        }
      }

      > .search-svg {
        position: absolute;
        z-index: 1;
        margin: 8px;
        overflow: visible !important;
      }
      .work-order-upload {
        .search-svg {
          position: absolute;
          z-index: 1;
          margin: 8px;
          overflow: visible !important;
        }
      }
    }

    .table-right-dropdown {
      // height: 34px;
      gap: 16px;
      flex-wrap: wrap;

      .created-calendar {
        position: relative;

        .ui.basic.button {
          height: 100%;
          border: 2px solid rgba(9, 30, 66, 0.14);
          border-radius: 5px;
          // font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #172b4d !important;
          width: 125px;
          padding: 8px 30px 8px 8px !important;
          box-shadow: none;
          margin-right: o;
        }

        .ui.basic.active.button {
          background: none !important;
        }

        .ui.labeled.icon.button > .icon {
          display: none;
        }

        > svg {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
        }
      }
    }

    .filters-wrapper > div > .ui.labeled.icon.button > .icon {
      display: none;
    }
  }

  .detail-intro {
    &.asset-info {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .asset-title-info {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .detail-intro-label {
      margin-bottom: 0px;
      color: #788ca5;

      /* Body Medium 14\20 */
      //font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;

      &.asset-label {
        min-width: 162px !important;
      }

      &.location-label {
        min-width: 93px !important;
      }

      &.location-label1 {
        min-width: 67px !important;
      }
    }
    .detail-intro-value {
      margin-right: 16px;
      margin-left: 16px;
      color: #172b4d;

      /* Body Large 16\24 */
      //font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      .detail-intro-link {
        color: #0c66e4;
        word-break: break-all;
        text-align: center;
        //font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .data-header {
    &.map-preview {
      padding: 19px;
      border-radius: 8px;
      border: 1px solid var(--grey_line, #e4e6ea);
      background: var(--grey-light, #f5f6f8);
    }
    .header-title {
      .header-id {
        label {
          color: var(--middle_grey, #788ca5);
          text-align: center;
          /* Body Large 16\24 */
          // font-family: 'Roboto';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: 0.5px;
        }
        span {
          margin-left: 4px;
          color: #172b4d;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }
    }
    .header-pill-container {
      display: flex;
      align-items: center;
      gap: 0px;
      .detail-title {
        word-wrap: break-word;
        width: 472px;
        // width: 250px;
        color: #172b4d;
        /* Headline Medium 28\36 */
        //font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        margin-bottom: 0px;

        .location-link {
          color: #0c66e4;
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 400;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
    .detail-updated-title {
      color: #788ca5;
      /* Body Small 12\16 */
      //font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
    }

    .wo-desc {
      .wo-desc-value {
        color: #788ca5;
        /* Title Large 22\28 */
        //font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 127.273% */
        letter-spacing: 0.15px;
      }
    }
    .data-header-image {
      max-width: 464px !important;
      max-height: 156px !important;
      // border-radius: 8px;
      // margin-bottom: 20px;
      object-fit: fill !important;
    }

    .no-img {
      width: 100% !important;
      height: 156px !important;
      border-radius: 8px;
      // margin-bottom: 20px;
      object-fit: fill !important;
    }

    // .data-header-image {
    //   // width: 100% !important;
    //   height: 230px !important;
    //   width: 400px !important;
    //   border-radius: 8px;
    //   margin-bottom: 20px;
    //   object-fit: contain;
    // }
  }

  .detail-header {
    .name {
      color: #172b4d;
      /* Title Large 22\28 */
      //font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 127.273% */
    }
    .detail-header-buttons {
      .header-button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
  .ui.tabular.menu .active.item {
    color: #172b4d;
    text-align: center;
    background-color: transparent;
    /* Title Medium 16\24 */
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }

  .ui.tabular.menu .item.active {
    border-bottom: 1px solid #172b4d;
    border-color: #172b4d !important;
  }
  .ui.tabular.menu .item {
    color: #788ca5;
    margin: 0px;
    padding-left: 32px !important;
    padding-right: 32px !important;
    background-color: transparent;
    color: #788ca5;
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;

    // &.items-menu{
    //   margin: 8px 10px 0px 5px !important;
    //   padding: 8px !important;
    //   display: flex !important;
    //   align-items: center !important;
    //   font-size: 12px !important;
    //   // gap: 15px !important;
    //   border: 1px solid !important;
    //   border-radius: 3px !important;
    //   background-color: #E9F2FF !important;

    //  :hover {
    //   background-color: white !important;
    //  }
    // }
  }

  .itembar-menu {
    margin: 8px 10px 0px 5px !important;
    display: flex !important;
    align-items: center !important;
    font-size: 12px !important;
    // gap: 15px !important;
    border: none !important;
    background-color: transparent !important;

    &.active {
      color: #0c66e4 !important;
      font-weight: 500 !important;
    }

    :hover {
      background-color: transparent !important;
    }

    .items-menu {
      padding: 8px 10px !important;
      background-color: #e9f2ff !important;
      border-radius: 4px !important;
    }
  }

  .ui.vertical.tabular.menu {
    border: none !important;
  }

  .location-tab {
    .ui.attached.tabular.menu {
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 1px;
    }

   
  }

  .vendor-tab {
    .ui.menu:not(.vertical) .item {
      display: flex;
      align-items: flex-end;
    }
    .ui.attached.tabular.menu {
      a:nth-child(2),
      a:nth-child(3) {
        margin-left: 0px;
      }
      margin-left: 20px;
      margin-right: 0;
      width: 78%;
    }
    .ui.tabular.menu .item {
      margin-left: -26px;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  .vendor-tab-rtl {
    .ui.menu:not(.vertical) .item {
      display: flex;
      align-items: flex-end;
    }
    .ui.attached.tabular.menu {
      a:nth-child(2),
      a:nth-child(3) {
        margin-right: 0px;
      }
      margin-left: 0px;
      margin-right: 20px;
      width: 60%;
    }
    .ui.tabular.menu .item {
      margin-right: -26px;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  .customer-tab {
    .ui.menu:not(.vertical) .item {
      display: flex;
      align-items: flex-end;
    }
    .ui.attached.tabular.menu {
      a:nth-child(2),
      a:nth-child(3) {
        margin-left: 0px;
      }
      margin-left: 20px;
      margin-right: 0;
      width: 61%;
    }
    .ui.tabular.menu .item {
      margin-left: -26px;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  .customer-tab-rtl {
    .ui.menu:not(.vertical) .item {
      display: flex;
      align-items: flex-end;
    }
    .ui.attached.tabular.menu {
      a:nth-child(2),
      a:nth-child(3) {
        margin-right: 0px;
      }
      margin-left: 0px;
      margin-right: 20px;
      width: 60%;
    }
    .ui.tabular.menu .item {
      margin-right: -26px;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  .po-tab {
    .ui.menu:not(.vertical) .item {
      display: flex;
      align-items: flex-end;
    }
    .ui.attached.tabular.menu {
      a:nth-child(2) {
        margin-left: 0px;
      }
      margin-left: 20px;
      margin-right: 0;
      width: 40%;
    }
    .ui.tabular.menu .item {
      margin-left: -26px;
    }
  }

  .po-tab-rtl {
    .ui.menu:not(.vertical) .item {
      display: flex;
      align-items: flex-end;
    }
    .ui.attached.tabular.menu {
      a:nth-child(2) {
        margin-right: 0px;
      }
      margin-left: 0px;
      margin-right: 20px;
      width: 40%;
    }
    .ui.tabular.menu .item {
      margin-right: -26px;
    }
  }
  .dashboard-tabs {
    .ui.attached.tabular.menu {
      padding: 0px;
      overflow-x: auto;
      padding-bottom: 1px;
    }
  }

  .image-dropzone {
    border-radius: 3px;
    border: 2px dashed #e4e6ea;
    background: rgba(233, 242, 255, 0.1);
    padding: 22px;

    &.file {
      border: 2px solid #e4e6ea;
      padding: 20px 16px 20px 0px;
      background-color: rgba(233, 242, 255, 0.3);
    }

    &.wo-dropzone{
      border-radius: 8px;
      border: 1px solid #0C66E4;
      background-color: #E9F2FF;

      svg{
        margin-top: 2px;
      }
    }
    .p1 {
      color: #44546f;
      text-align: center;
      /* Body Large 16\24 */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
    }
    .p2 {
      color: #788ca5;
      /* Body Medium 14\20 */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .warrenty-file-dropzone {
    border-radius: 2px;
    border: 2px solid rgba(23, 43, 77, 0.14);
    background: rgba(233, 242, 255, 0.3);
    padding: 16px;

    &.qr-code-conrtainer {
      margin-top: 24px !important;
    }
    .p1 {
      color: #172b4d;
      /* Title Large 22\28 */
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 127.273% */
    }

    .p2 {
      color: #788ca5;
      /* Body Medium 14\20 */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
    }
  }

  .id-text-style {
    font-weight: 500 !important;
    letter-spacing: 0.15px !important;
  }

  .ui.dropdown {
    .menu {
      background-color: #ffffff !important;
    }
    .scrolling.menu {
      border-top: none !important;
    }
  }
  .form-date-picker {
    .rmdp-container {
      width: 105% !important;

      .rmdp-input {
        width: 105% !important;
      }
    }

    .date-picker-image {
      top: 5px !important;
      left: -10px !important;
    }
  }

  .kanbar-view-container {
    display: flex;
    gap: 16px;
    overflow-x: scroll;
    padding-bottom: 10px;
    .kanbar-header {
      width: 332px;
      // padding: 10px 16px;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #e4e6ea;

      &.blue {
        background: #e9f2ff;
      }
      &.yellow {
        background: rgba(226, 178, 3, 0.1);
      }
      &.red {
        background: rgba(209, 64, 64, 0.1);
      }
      &.grey {
        background: rgba(23, 43, 77, 0.1);
      }
      &.green {
        background: rgba(79, 171, 104, 0.1);
      }
      &.darkblue {
        background: #0005631a;
      }

      &.light-purple {
        background: #554feb1a;
      }

      .header-color {
        background-color: #0c66e4;
        height: 7px;
        border-radius: 4px;
        border: 1px solid #e4e6ea;
      }

      .svg-header {
        margin-top: -8px;
        margin-left: -1px;
      }

      .workorder-intro-value {
        padding: 4px 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.1px;
        padding-top: 0px;

        &.grey {
          font-size: 14px !important;
          color: #788ca5 !important;
        }

        .grey-container {
          margin-left: 8px;
          background: #f5f6f8;
          border-radius: 6px;
          padding: 5px 6px 5px 0px;
          width: 145px !important;
        }
        .detail-intro-link {
          color: #0c66e4;
          text-align: start;
          word-break: break-all;
          //font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: 0.5px;
          text-decoration-line: underline;
          cursor: pointer;
        }
        .people-activated {
          font-size: 14px !important;
        }
      }

      .kanban_total_count {
        border-radius: 10px;
        border: 1px solid #788ca5;
        padding: 1px 8px;
        color: #788ca5;
        /* Body Small 12\16 */
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.4px;
      }
    }
    .kanban-card {
      padding: 16px;
      margin-top: 8px;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #e4e6ea;
      .detail-container {
        .title {
          color: #172b4d;
          /* Title Medium 16\24 */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          letter-spacing: 0.25px;
          white-space: normal;
          word-wrap: break-word;
        }
        .description {
          color: #788ca5;
          /* Body Medium 14\20 */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.25px;
          white-space: normal;
          word-wrap: break-word;
        }
      }
      .kanban-due-date {
        color: #172b4d;
        text-align: center;
        /* Body Large 16\24 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 150% */
        letter-spacing: 0.25px;
      }
      .kanban-due-time {
        color: #788ca5;
        text-align: center;
        /* Body Medium 14\20 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
      }
      .kanban-created-by {
        color: #172b4d;
        text-align: center;
        /* Body Large 16\24 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 150% */
        letter-spacing: 0.25px;
        white-space: normal;
        word-wrap: break-word;
      }
      .kanban-org {
        color: #172b4d;
        /* Body Large 16\24 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 150% */
        letter-spacing: 0.25px;
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }
  .fc .fc-more-popover .fc-popover-body {
    max-height: 300px;
    overflow-y: scroll;
  }
  .all_option_dropdown_text {
    color: #172b4d;
    text-align: right;
    // font-family: 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
  }
  .multi-profile-container {
    display: flex;
    img {
      margin: 0px;
      margin-left: -15px !important;
    }

    .img-first {
      margin-left: 0px !important;
    }
    img:nth-child(2) {
      // right: 20px;
      margin-left: -20px;
    }
    img:nth-child(3) {
      margin-left: -40px;
    }

    &.no-hover img {
      z-index: 0 !important;
    }
    img:hover {
      z-index: 1;
    }

    .primary-profile-container {
      position: relative;
      display: inline-block;

      .custom-primary-tooltip {
        background-color: #f8fbff; /* Light blue background */
        color: #172b4d; /* Dark text color */
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        border-radius: 12px; /* Rounded corners */
        padding: 10px 15px; /* Padding for content */
        font-size: 14px; /* Font size */
        line-height: 1.5; /* Line spacing */
        text-align: left; /* Align text to the left */
        max-width: auto; /* Optional: Limit tooltip width */
        border: 1px solid #e4e6ea;

        &.place-top::before {
          content: "";
          position: absolute;
          border-left: 9px solid transparent; /* Slightly larger than the arrow */
          border-right: 9px solid transparent;
          bottom: -7px; /* Position slightly lower than the arrow */
          left: 50%;
          margin-left: -9px;
          border-top-color: #e4e6ea !important; /* The red border color */
          border-top-style: solid;
          border-top-width: 7px;
          z-index: -1; /* Place behind the arrow */
        }

        &.place-top::after {
          content: "";
          position: absolute;
          border-left: 8px solid transparent; /* Original arrow */
          border-right: 8px solid transparent;
          bottom: -6px;
          left: 50%;
          margin-left: -8px;
          border-top-color: #f8fbff !important;
          border-top-style: solid;
          border-top-width: 6px;
        }
        // &.place-top::after {
        //   border-top-color: #F8FBFF !important;
        // } &.place-left::after {
        //   border-left-color: #F8FBFF !important;
        // } &.place-right::after {
        //   border-right-color: #F8FBFF !important;
        // } &.place-bottom::after {
        //   border-bottom-color: #F8FBFF !important;
        // }
      }

      span {
        position: absolute;
        top: -9px;
        right: -7px;
        z-index: 1;
        cursor: pointer;
        display: none; /* Initially hidden */
      }

      &.hover-div {
        &:hover span {
          display: block; /* Show on hover */
        }
      }

      // &:hover &.hover-div span {

      // }
    }
  }
}

.direction-rtl {
  .new-listing-container {
    font-family: "Noto Kufi Arabic" !important;
  }
}

.workorder-header-buttons {
  .header-button {
    margin-left: 8px;
    margin-right: 8px;
  }
}

// new header added css
.flex-wrap-ans {
  flex-wrap: wrap;
}
.white-space-no {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .new-listing-container .new-listing-filter .filters .views .list-view-option {
    grid-gap: 15px;
    gap: 15px;
  }
}

.inventory-details {
  border-left: 1px solid rgba(228, 230, 234, 1);
  border-bottom: 1px solid rgba(228, 230, 234, 1);
  border-right: 1px solid rgba(228, 230, 234, 1);
  border-radius: 0px 0px 3px 3px;
  background: rgba(233, 242, 255, 0.2);
}

// .details-wrapper > div p {
//   width: 90%;

//   &.set-width{
//     width: 69%;
//   }
// }
