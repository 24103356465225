.rtl-body {
  body {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  .workorder-intro-value.grey-container-hover {
    width: 149px !important;
  }

  .aside-modal {
    &.preview {
      .modal-header {
        .workorder-header-buttons {
          .action-options {
            .dropdown-menu {
              .items {
                svg {
                  margin-left: 12px;
                }
              }
            }
          }
        }
      }
      .body {
        .pane-body {
          .comments-container {
            .comments-data {
              .message {
                .message-content {
                  .status-body {
                    svg {
                      rotate: 180deg;
                    }
                  }
                }
              }
            }
            .upload-container {
              padding: 0px 0px 0px 10px;

              .upload-section {
                .send-button {
                  rotate: 180deg;
                }
              }
            }
          }
        }
      }
    }
  }

  .add-button {
    min-width: 142px;
    .add-button-img {
      transform: translateX(0px);
    }

    .add-button-text {
      text-align: end;
    }

    &.wr {
      min-width: 163px;
    }

    &.po {
      min-width: 167px;
    }
    &.procedure {
      min-width: 135px;
    }

    &.part {
      min-width: 124px;
    }

    &.set {
      min-width: 138px;
    }
    &.people {
      min-width: 127px;
    }
    &.asset {
      min-width: 127px;
    }
    &.team {
      min-width: 118px;
    }
    &.customer {
      min-width: 132px;
    }
    &.vendor {
      min-width: 109px;
    }
    &.location {
      min-width: 119px;
    }
    &.jobTitle {
      min-width: 191px;
    }
    &.category {
      min-width: 110px;
    }
  }

  .ui.popup {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }

  textarea {
    font-family: "Noto Kufi Arabic" !important;
  }

  .status-container .status-button .status-text,
  .status-container .status-dropdown-wo .status-item {
    padding: 8px 10px 8px 10px;
  }

  .dropdown-tooltip {
    width: 291px;

    &.team {
      left: -31px !important;
    }

    .dropdown-search-input {
      input {
        min-width: 262px !important;
      }
    }
    .dropdown-options {
      .dropdown-item {
        &.avatar-selected {
          border-right: 2px solid #0c66e4 !important;
          border-left: 0px;
        }

        &:hover {
          border-right: 2px solid #0c66e4 !important;
          border-left: 0px !important;
        }
      }
    }
  }

  .metrics-item {
    border-left: 1px solid #d1d5db;
    border-right: none !important;

    &:last-child {
      border-left: none !important;
    }

    &:hover {
      border-left: 0px !important;

      // cursor: pointer;
    }

    &:hover + .metrics-item {
      border-right: 1px solid #d1d5db !important; /* Blue border for next item */
    }
  }

  .custom-tooltip {
    top: 58%;
    left: 40%;

    .tooltip-content {
      width: 290px;

      .cost-breakdown {
        .cost-row {
          .cost-label {
            width: 175px;
          }
        }
      }
    }
  }

  .sidebar {
    border-right: 1px solid #ddd;
  }
  .workorder-intro-label {
    &.part-label {
      min-width: 124px !important;
    }
    &.work-request-label {
      min-width: 124px !important;
    }
    &.work-order-label {
      min-width: 75px !important;
    }
    &.set-label {
      min-width: 123px !important;
    }
    &.location-label2 {
      min-width: 124px !important;
    }
    &.personal-label {
      min-width: 204px !important;
    }
    &.attachment-label {
      min-width: 111px !important;
    }
    &.customer-label {
      min-width: 195px !important;
    }
    &.vendor-label {
      min-width: 195px !important;
    }
  }
  .ui.dropdown .menu > .item > .description {
    float: inline-end !important;
  }

  .rmdp-week-day {
    width: auto !important;
  }

  .rmdp-calendar .rmdp-week-day,
  .rmdp-header,
  .rmdp-calendar .rmdp-day {
    font-family: "Noto Kufi Arabic";
  }

  .work-order-preview-tooltip {
    font-family: "Noto Kufi Arabic" !important;
  }

  .action-custom-menu-dropdown .dropdown.show .menu {
    left: 0 !important;
    right: auto !important;

    &.asset {
      width: 211px !important;
    }
  }
  .Custom-search-dropdown.location-drop .dropdown .menu .custom-dropdown-item {
    padding: 6px 12px 4px 0px;
  }
  .circular-label {
    margin-left: 5px;
  }

  .res {
    direction: ltr;
    text-align: left;
    font-family: "Noto Kufi Arabic" !important;
  }

  table th,
  table td {
    border: none;
    padding: 3px;
    text-align: right;
    font-size: 14px;
    color: rgba(23, 43, 77, 1);
  }

  .dashboard-container
    .graph-container
    .completion-column
    .completion-column-data
    .completion-status-part
    .wo-data-container
    .wo-data-status
    .status-container.assignee {
    height: 371px;
  }
  input {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  //  font-family: sans-serif;

  .procedure-intro-value {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  .procedure-intro-label {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }

  button {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  .rdt_TableHead {
    .rdt_TableHeadRow {
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }
  }
  .rdt_TableRow {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  .nCTcL {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  .modal {
    font-family: "Noto Kufi Arabic", sans-serif !important;
    direction: rtl;
    .header {
      text-align: right;
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }
    // .status-modal-popup-message {
    //   font-family: 'TheSans-Plain', sans-serif !important;
    // }
    button {
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }

    input {
      text-align: start !important;
    }
    .content {
      text-align: start;
    }
    .actions {
      text-align: end !important;
    }
  }
  .Category-dropdown {
    .dropdown {
      font-family: "Noto Kufi Arabic", sans-serif;
    }
  }
  .Date-dropdown {
    .dropdown {
      font-family: "Noto Kufi Arabic", sans-serif;
    }
  }

  .date-picker-image {
    right: 93% !important;
    left: 0px !important;
    &.procedure {
      right: -31px !important;
      left: 0px !important;
    }
    &.people {
      right: 441px !important;
      left: 0px !important;
    }
    &.po {
      right: 471px !important;
      left: 0px !important;
    }
    &.location {
      right: 6px !important;
      // left: 0px !important;
    }
  }

  .new-listing-container {
    .aside-modal {
      .ui.basic.buttons .button.day-btn {
        width: 50px;
      }
    }
    .multi-profile-container {
      img:nth-child(2) {
        right: -20px !important;
      }
      img:nth-child(3) {
        right: -40px !important;
      }

      .more-image {
        right: -11px !important;
      }
      img {
        margin-left: 0px !important;
        right: 0px !important;
      }
    }
  }

  .procedure-container {
    .multi-profile-container {
      img:nth-child(2) {
        right: -20px !important;
      }
      img:nth-child(3) {
        right: -40px !important;
      }
      img {
        margin-left: 0px !important;
        right: 0px !important;
      }
    }
  }

  .loc-dropdown {
    img {
      width: auto !important;
      height: auto !important;
      margin-left: 0.78571429rem !important;
    }
  }
  .sub-location-dropdown-option {
    margin-right: 15px !important; /* Adjust as needed */

    img {
      height: auto !important;
      width: auto !important;
    }

    // &:hover{
    //   margin-left: 0px !important;
    // }
  }

  .grandchild-asset-dropdown-option {
    margin-right: 30px !important; /* Adjust as needed */

    img {
      height: auto !important;
      width: auto !important;
    }

    // &:hover{
    //   margin-left: 0px !important;
    // }
  }

  .people-dropdown {
    .ui.label > img {
      width: auto !important;
      vertical-align: middle;
      height: auto !important;
      margin-left: 5px;
      background-color: #e8e8e8 !important;
    }
    .ui {
      display: inline-flex !important;
    }

    .ui.label > .delete.icon {
      margin-top: -4px !important;
      margin-right: 5px !important;
    }
  }
  .rdt_TableRow {
    div {
      button {
        svg {
          rotate: 180deg;
        }
      }
    }
  }

  .progress-number {
    margin-right: 1rem !important; /* Adjust margin as needed */
    margin-left: 0px !important; /* Adjust margin as needed */
    white-space: nowrap !important; /* Prevent number from wrapping */
  }

  .ui.input > input {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }

  time {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }

  .Toastify__toast-body {
    > div:last-child {
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }
  }

  .rmdp-day {
    font-family: "Noto Kufi Arabic" !important;
  }

  .ui.tabular.menu {
    padding: 0 0;
  }

  .people-datepicker {
    font-family: "Noto Kufi Arabic" !important;
  }

  ::placeholder {
    font-family: "Noto Kufi Arabic" !important;
  }

  // .all-cards{
  //  direction: ltr !important;
  //   text-align: left !important;
  // }

  // .text-right {
  //   text-align: left !important;
  // }
  .change-for-job-btn .new-grey-btn {
    margin-left: 28px;
  }
  .set-position-of-percentage {
    margin: 5px 0px 5px 28px;
  }
  .set-position-of-percentage .span-number-per {
    padding-left: 48px;
  }
  .ml-22 {
    margin-left: 0rem !important;
    margin-right: 5.5rem !important;
  }

  .set-input {
    .ui.input > input {
      padding: 3px 31px !important;
    }
  }

  .rmdp-container > div:last-child {
    /* Add your styles here */
    left: -180px !important;
    right: 0px;
    top: 42px !important;

    transform: none !important ;
  }
  .rtl-custom-menu {
    .dropdown.show .menu {
      left: 19px !important;
      right: auto !important;
    }
  }

  .labor-date-picker {
    width: 425px !important;
  }

  .rtl-placeholder {
    input {
      direction: rtl; /* Align text and placeholder from the right */
      text-align: right; /* Align input text from the right */
    }
  }

  // .bBHNFv svg {
  //     rotate: 180deg;
  // }

  .ml-16 {
    margin-left: 0rem !important;
    margin-right: 5.1rem !important;
  }
  .ml-15 {
    &.po {
      margin-left: 0 !important;
      margin-right: 3.75rem !important;
    }
  }
  .input.ml-22 {
    margin-left: 0rem !important;
    margin-right: 4.5rem !important;
  }
  .grid-child-element.set {
    // padding: 6px 2px 4px 3px;
    padding: 6px 10px 4px 4px;
    font-weight: 500;
    font-size: 13px;
  }

  .loc-dropdown {
    &.wos {
      display: flex !important;
      flex-direction: row !important;

      // .text{
      //   right:1em !important
      // }

      .menu > .item {
        text-align: right !important;
      }

      .dropdown.icon {
        right: 267px !important;
        left: 0px !important;
      }

      .clear {
        right: 267px !important;
        left: 0px !important;
      }
    }
  }

  .custom-dropdown-menu2 {
    display: flex !important;
    flex-direction: row !important;

    // .text{
    //   right:1em !important
    // }

    .menu > .item {
      text-align: right !important;
    }

    .dropdown.icon {
      right: 267px !important;
      left: 0px !important;
    }

    .clear {
      right: 267px !important;
      left: 0px !important;
    }
  }

  .preview-details-wrapper
    .details-list
    .list-item
    .item-label.procedure-label {
    margin-right: 0px;
  }

  .assignee-data-rtl-body {
    .ui.selection.dropdown .menu > .item {
      text-align: right;
    }
    .ui.dropdown > .text {
      text-align: right;
      width: 100%;
      left: 14px;
      position: relative;
    }
    .ui.selection.dropdown > .dropdown.icon,
    .ui.selection.dropdown > .search.icon {
      cursor: pointer;
      position: absolute;
      width: auto;
      height: auto;
      line-height: 1.21428571em;
      top: 0.78571429em;
      right: inherit;
      left: 3%;
    }
  }
}
