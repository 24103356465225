.aside-modal {
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  max-width: 50vw;
  height: 100%;
  transition: transform 0.2s ease-in;
  z-index: 100;

  .alert {
    margin-inline: 18px;
    background-color: #ff3835;
    text-align: center;
    color: #d14040;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-top: 32px;
    margin-bottom: 0;
    border-radius: 4px;
    border: 1px solid #d14040;
    background: rgba(209, 64, 64, 0.1);
  }

  .aside-modal .body .buttons.basic .button.active {
    background: #f5f6f8 !important;
    border: none !important;
  }

  .body {
    .barcode-btns {
      gap: 15px;
      button {
        border-radius: 8px;
        border: 2px solid #e4e6ea;
        font-size: 14px;
        line-height: 20px;
        color: #44546f;
        padding: 6px 4px 6px 5px !important;

        &.active {
          border: 2px solid #0c66e4;
          background-color: #e9f2ff;
          color: #0c66e4;
        }
      }
    }
    .buttons {
      &.basic {
        .button {
          &.active {
            background: #e9f2ff !important;
            color: #0c66e4;
          }
          &.negative {
            background: #fff6f6 !important;
            color: #9f3a38 !important;
          }
        }
      }
    }
    .store-details {
      .ui.segment {
        background-color: rgba(233, 242, 255, 0.3);
        border-radius: 8px;
        border: 2px solid #0c66e4;
      }
    }
  }

  .body {
    .buttons {
      &.basic {
        &.add-people-btn {
          .button {
            border: none !important;
            border-radius: 0px !important;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: "Roboto";
            font-weight: 500;
            color: #788ca5;
            background-color: transparent !important;
            padding: 0;

            &.active {
              color: #172b4d !important;
              border-bottom: 1px solid black !important;
            }
            &.negative {
              color: #9f3a38 !important;
            }
          }
        }
      }
    }
  }

  &.active {
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.preview {
    .body {
      padding: 30px 0;
      overflow-x: hidden;

      .pane-body {
        padding: 0 25px;

        &.wo {
          padding: 0 16px 0 21px;
        }

        .comments-tab {
          .ui.attached.tabular.menu {
            padding: 0px;

            .item {
              gap: 9px;
            }
          }
        }

        .nav {
          display: flex;
          gap: 16px;

          .tab {
            display: flex;
            width: 126px;
            align-items: center;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            border-radius: 12px;
            padding: 5px 8px;
            border: 1px solid #788ca5;
            border-radius: 12px;
            background: none;
            color: #788ca5;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              background-color: #f3f4f6;
            }
            &.active {
              background-color: #172b4d;
              color: white;

              border-color: #172b4d;
            }
          }
        }
        .comments-container {
          .comments-data {
            height: 675px;
            overflow: auto;
            &.increase-height {
              height: 730px;
            }

            .message {
              display: flex;
              cursor: pointer;

              background: #fff;
              padding: 10px 6px 0px 6px;
              color: #172b4d;
              font-size: 16px;
              line-height: 24px;
              gap: 5px;

              &.unread-comment {
                background: #f5f6f880;
              }
              &.unread {
                background: rgba(233, 242, 255, 0.4);
              }

              .message-content {
                flex: 1;

                .message-header {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .name {
                    font-weight: 600;
                  }

                  .sub-name {
                    font-weight: 400;
                  }
                }

                .time-data {
                  font-size: 12px;
                  color: #788ca5;
                }

                .message-body {
                  color: #44546f;

                  .media-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;

                    .media-item {
                      width: 300px;
                      height: 170px;
                      box-sizing: border-box;

                      .video-container {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .data-header-image,
                        .comments-video {
                          border-radius: 8px;
                        }
                      }
                    }
                  }

                  .non-media-container {
                    .wo-dropzone {
                      border-radius: 8px;
                      background-color: #e9f2ff;
                    }
                  }

                  &.prev-content {
                    color: #44546fcc;

                    .fix-width {
                      width: 200px;
                    }
                  }

                  .hour {
                    padding: 4px 6px;
                    background: #e9f2ff;
                    border-radius: 8px;
                    color: #0c66e4;
                  }
                }

                .status-body {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  color: #6b7280;

                  .status-change {
                    display: flex;
                    align-items: center;

                    .status-arrow {
                      font-size: 16px;
                      color: #6b7280;
                    }

                    .status {
                      font-size: 16px;
                      font-weight: 400;
                      padding: 0px 6px;
                      border-radius: 4px;
                      text-align: center;
                      line-height: 24px;
                      color: #ffffff;

                      &.open {
                        background-color: #0c66e4;
                      }

                      &.inProgress {
                        background-color: #e2b203;
                      }
                      &.onHold {
                        background-color: #db2828;
                      }
                      &.completed {
                        background-color: #000563;
                      }
                      &.inReview {
                        background-color: #4fab68;
                      }
                      &.reOpen {
                        background-color: #554feb;
                      }
                      &.cancelled {
                        background-color: #788ca5;
                      }
                    }
                  }
                }
              }

              .hover-actions {
                position: absolute;
                top: -16px;
                right: 10px;
                display: flex;
                gap: 10px;
                background: #ffffff;
                padding: 8px;
                border: 1px solid #e4e6ea;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                .action-btn {
                  background: none;
                  border: none;
                  color: #44546f;
                  font-size: 12px;
                  cursor: pointer;

                  &.delete-btn {
                    color: #e63946;
                  }
                }
              }
            }
          }
          .upload-container {
            padding: 0px 10px 0px 0px;
            border: 1px solid #e4e6ea;
            border-radius: 8px;
            background-color: #f5f6f84d;
            margin-top: 20px;

            .attachment-section {
              display: flex;
              flex-direction: column;
              margin: 10px 10px 0 10px;

              .attachment-row {
                display: flex;
                align-items: flex-start;
                gap: 10px;

                .vertical-divider {
                  width: 1px;
                  background-color: #ddd;
                  align-self: stretch;
                }

                .attachments-container {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 10px;

                  .attachment-item {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 10px;
                    padding: 20px 16px;
                    background-color: #ffffff;
                    border: 1px solid #e4e6ea;
                    border-radius: 8px;
                    min-width: 152px;
                    position: relative;

                    .attachment-icon {
                      width: 32px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      img {
                        width: 100%;
                        height: auto;
                      }
                    }

                    .attachment-details {
                      display: flex;
                      flex-direction: column;

                      .attachment-name {
                        font-size: 14px;
                        font-weight: 600;
                        color: #2b2b2b;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                  }

                  &.images-section {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                  }

                  .attachment-item-image {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;

                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 8px;
                      object-fit: cover;
                    }

                    .remove-attachment {
                      position: absolute;
                      top: -9px;
                      right: -6px;
                      cursor: pointer;

                      &.docs {
                        top: -11px;
                      }

                      .loading-spinner {
                        width: 20px;
                        height: 20px;

                        .spinner {
                          animation: rotate 1s linear infinite;
                          width: 100%;
                          height: 100%;

                          .path {
                            stroke: #007bff;
                            stroke-linecap: round;
                            animation: dash 1.5s ease-in-out infinite;
                          }
                        }

                        @keyframes rotate {
                          100% {
                            transform: rotate(360deg);
                          }
                        }

                        @keyframes dash {
                          0% {
                            stroke-dasharray: 1, 150;
                            stroke-dashoffset: 0;
                          }
                          50% {
                            stroke-dasharray: 90, 150;
                            stroke-dashoffset: -35;
                          }
                          100% {
                            stroke-dasharray: 90, 150;
                            stroke-dashoffset: -125;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .upload-section {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .upload-options {
                .ui.input > input {
                  padding: 3px 0px !important;
                  padding: 8px 0px;
                  background-color: #f5f6f84d !important;
                  min-width: 350px;
                  border: none !important;
                  box-shadow: none !important;
                  outline: none !important;
                }

                .upload-button {
                  background: none;
                  border: none;
                  padding: 8px;
                  cursor: pointer;
                  color: #6b7280;

                  &:hover {
                    color: #374151;
                  }
                }
              }

              .send-button {
                background: #2563eb;
                border: none;
                padding: 4px 8px;
                border-radius: 8px;
                color: white;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  background: #1d4ed8;
                }
              }
            }
          }
        }

        .preview-details-wrapper {
          .details-wrapper {
            .notes-container {
              .closeout-notes-form {
                .edit-notes {
                  background-color: #f5f6f8;
                  &:focus {
                    border: 1px solid #e4e6ea !important;
                  }
                }
              }

              .files-container {
                .detail-file-container {
                  border: 1px solid #e4e6ea;
                  padding: 9px 16px;
                  background-color: #e9f2ff4d;
                  border-radius: 8px;
                  font-size: 16px;
                  color: #172b4d;
                }
              }
            }
          }
        }
      }
    }
  }

  .details-wrapper {
    .icon-container {
      gap: 5px;
    }
    &.work-request {
      margin-top: -20px;
    }

    .notes-container {
      border: 1px solid #e4e6ea;
      border-radius: 6px;
      padding: 8px;
    }
    .status-dropdown {
      width: 200px;
      border-radius: 3px;
      border: 2px solid rgba(9, 30, 66, 0.14);
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: 30px;

        .label:not(.circular) {
          font-size: 13px;
          color: #707070;
          display: block;
          background: transparent;
          border-radius: 0;
          height: auto;
          width: auto;
          margin-bottom: 5px;
        }

        .big-label {
          font-size: 15px;
          margin-bottom: 5px;
        }

        .value {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .modal-header {
    border-bottom: 1px solid #ebedf3;
    padding: 30px 25px 25px;
    background-color: rgba(233, 242, 255, 0.5) !important;
      
    .workorder-header-buttons,.detail-header-buttons,.procedure-detail-header-buttons {
      .action-options {
        .dropdown-menu {
          padding: 0;
          width: 180px;
          border-radius: 0px;

          .items {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 8px 5px 8px 20px !important;

            svg {
              margin-right: 12px;
            }

            &:hover {
              background-color: #f5f6f8;
              border-left: 2px solid #0c66e4;
            }
          }
        }
      }
    }

    .body {
      &.barcode-btns {
        button {
          border-radius: 8px;
          border: 2px solid #e4e6ea;
          font-size: 14px;
          line-height: 20px;
          color: #44546f;
        }
      }
    }

    > .flexbox {
      width: 100%;
    }

    div {
      i {
        cursor: pointer;
        color: #878c90;
        margin: 0 5px;

        padding: 0;
        &:last-child {
          margin-right: 0;
        }
      }
      span {
        font-size: 20px;
        font-weight: 700;
      }
      .work-order-preview-tooltip {
        font-size: 9px !important;
        font-weight: 400;
        font-family: "Roboto";

        &.parts {
          line-height: 18px !important;
        }
      }
    }

    .name {
      color: #172b4d;

      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .ui {
    &.card {
      &.card-selectable {
        .header {
          height: auto;
          border-bottom: 0;
          padding: 0;
        }
      }
    }
  }

  .body {
    padding: 30px 25px;
    overflow: auto;

    .field {
      .ui {
        &.dropdown {
          &.selection {
            .label {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.aside-modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
  visibility: hidden;
  transition: background-color 0.2s ease-in;

  &.active {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.ui {
  &.header,
  .header {
    display: block;
    height: auto;
  }
  &.modal {
    top: auto;
    left: auto;
    height: auto;
  }

  &.button {
    &.basic {
      &.label {
        height: 100%;
      }
    }
  }

  &.card {
    &.card-selectable {
      &:hover {
        .content {
          background: rgba(0, 0, 0, 0.05);
          .header {
            background: transparent;
          }
        }
      }

      &.selected {
        .content {
          background: #21ba45;
          color: #fff;
          .header {
            background: transparent;
            color: #fff;

            i {
              color: #fff;
            }
          }
          .description {
            color: #fff;
          }
        }
      }
    }
  }

  &.tabular {
    &.menu {
      margin-bottom: 30px !important;
      padding: 0 25px;

      &::after {
        display: none;
      }

      .item {
        border: 0;
        padding: 0 !important;
        margin-right: 20px;
        &.active {
          border: 0;
          border-bottom: 2px solid;
          border-color: #2185d0 !important;
        }
      }
    }
  }
  &.attached.tab.bottom {
    border: 0;
    padding: 0;
  }
}

.accordion-row {
  cursor: pointer;

  .aside-counter {
    border-radius: 2px !important;
    background: var(--blue, #0c66e4);
    padding: 0px 10px;
    color: #fff;
    span {
      line-height: 25px;
      font-size: 14px;
      text-align: center;
      margin: auto;
      border: 1px solid;
      height: 25px;
      width: 25px;
      border-radius: 3.7px;
      background-color: #2185d0;
      color: #fff;
    }

    i {
      font-size: 18px;
      color: #000;
    }
  }
}

.sub-header-count {
  .sub-location-tooltip {
    padding: 10px;
    background-color: #788ca5;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    border-radius: 5px;
  }
}

.custom-container {
  border-radius: 4px;
  background: #f5f6f8;
  padding: 7px 0px 0px 11px;

  .custom-label {
    color: var(--main-text, #172b4d);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.sub-location {
  cursor: pointer;
  background-color: rgba(13, 13, 14, 0.03);
}

.buttun-type-link {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  box-shadow: none;
  color: #2185d0;
  line-height: 1;
  i {
    color: #2185d0;
  }
  p {
    color: #0c66e4;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .customer-add-btn {
    background: #e9f2ff;
    padding: 4px;
    border-radius: 24px;
  }

  &.remove {
    color: #f11818;
    i {
      color: #f11818;
    }
  }
}

.configure-icon {
  svg {
    height: 23px;
    width: 23px;
    margin-left: -2px;
  }
}

.configure-text {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Segoe UI, Helvetica, Arial, sans-serif !important;

  &.arabic-text {
    font-family: "Noto Kufi Arabic" !important;
  }
}
